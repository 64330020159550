<template>
  <el-card class="card-continent">
    <div slot="header" class="clearfix">
      <nuxt-link
        :to="{
          name: 'search-type',
          params: {
            type: $t('router.search_type.type.continent'),
            value: slug,
            tab: $t('router.search_type.tab.tours'),
          },
        }"
        @click.native="handleLoading"
      >
        <h3
          ><i class="fa fa-globe" /> {{ $t(`${base}.trips_to`, { name }) }}</h3
        >
      </nuxt-link>
    </div>
    <div class="container-countries">
      <h4 v-for="country in countriesList.slice(0, 10)" :key="country.slug">
        <LadHomeDestinationCountry v-bind="country" :base="base" />
      </h4>
    </div>

    <nuxt-link
      :to="{
        name: 'search-type',
        params: {
          type: $t('router.search_type.type.continent'),
          value: slug,
          tab: $t('router.search_type.tab.tours'),
        },
      }"
      class="country-link el-button el-button--text"
      @click.native="handleLoading"
    >
      {{ $t(`general.show_more`) }}
    </nuxt-link>
  </el-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'LadHomeDestinationContinent',
  components: {
    LadHomeDestinationCountry: () =>
      import(
        /* webpackChunkName: "lad-home-destination-country" */ './country'
      ),
  },
  props: {
    code: { type: String, required: true },
    name: { type: String, required: true },
    slug: { type: String, required: true },
    countries: { type: Array, required: true },
    base: { type: String, required: true },
  },
  computed: {
    ...mapState('settings', {
      countriesList(state) {
        const countries = []

        this.countries.forEach((code) =>
          countries.push(
            state.popular_countries.find((t) => t.alpha_3_code === code)
          )
        )

        return countries.filter((country) => country)
      },
    }),
  },
  methods: {
    handleLoading() {
      this.$nuxt.$emit('custom-loading', { enabled: true, source: 'search' })
    },
  },
}
</script>

<style lang="scss">
.card-continent {
  .container-countries {
    h4 {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      color: inherit;
      width: fit-content;
    }
  }
}
</style>
