var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-card',{staticClass:"card-continent"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('nuxt-link',{attrs:{"to":{
        name: 'search-type',
        params: {
          type: _vm.$t('router.search_type.type.continent'),
          value: _vm.slug,
          tab: _vm.$t('router.search_type.tab.tours'),
        },
      }},nativeOn:{"click":function($event){return _vm.handleLoading.apply(null, arguments)}}},[_c('h3',[_c('i',{staticClass:"fa fa-globe"}),_vm._v(" "+_vm._s(_vm.$t(`${_vm.base}.trips_to`, { name: _vm.name })))])])],1),_vm._v(" "),_c('div',{staticClass:"container-countries"},_vm._l((_vm.countriesList.slice(0, 10)),function(country){return _c('h4',{key:country.slug},[_c('LadHomeDestinationCountry',_vm._b({attrs:{"base":_vm.base}},'LadHomeDestinationCountry',country,false))],1)}),0),_vm._v(" "),_c('nuxt-link',{staticClass:"country-link el-button el-button--text",attrs:{"to":{
      name: 'search-type',
      params: {
        type: _vm.$t('router.search_type.type.continent'),
        value: _vm.slug,
        tab: _vm.$t('router.search_type.tab.tours'),
      },
    }},nativeOn:{"click":function($event){return _vm.handleLoading.apply(null, arguments)}}},[_vm._v("\n    "+_vm._s(_vm.$t(`general.show_more`))+"\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }